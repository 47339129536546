<template>
  <div class="container-form-login">
    <div class="content-login text-center">
      <p class="text-title mon-bold mt-5">{{ texts.signin.textTitle }}</p>
      <div class="text-description mon-regular mt-5">
        <p
          class="ml-5"
          v-html="texts.signin.textDescription"
        />
      </div>
      <div class="mt-9">
        <div
          @click="redirect('SigninCustomer')"
          class="card-content mb-9"
        >
          <div class="border-line-customer" />
          <div class="pa-5">

            <div class="display-flex align-items-center">
              <div>
                <p class="text-enterprise-title mon-bold">{{ texts.signin.textTitleCustomer }}</p>
                <p class="text-subdescription mon-regular">
                  {{
                    (selectLanguage == 'sp')
                    ? 'Para registrar tu empresa y poder hacer uso de todas las herramientas ideales para comprar y abastecerte con las distintas variedades de artículos que tenemos disponibles.'
                    : 'To register your company and be able to use all the ideal tools to buy and stock up on the different varieties of items that we have available.'
                  }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <img
                width="35px"
                class="icon-arrow"
                src="@/assets/icons/arrow-right-circle.svg"
                alt=""
              >
            </div>
          </div>
        </div>
        <div
          @click="redirect('SigninVendor')"
          class="card-content mb-9"
        >
          <div class="border-line-vendor" />
          <div class="pa-5">
            <div class="display-flex align-items-center">
              <div>
                <p class="text-enterprise-title mon-bold">{{ texts.signin.textTitleVendor }}</p>
                <p class="text-subdescription mon-regular">
                  {{
                    (selectLanguage == 'sp')
                    ? 'Para registrar tu empresa y poder hacer uso de todas las herramientas ideales para comerciar toda la gama de PRODUCE que tengas a disposición. '
                    : 'To register your company and be able to make use of all the ideal tools to trade the full range of PRODUCE that you have available.'
                  }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <img
                width="35px"
                class="icon-arrow"
                src="@/assets/icons/arrow-right-circle.svg"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  name: "SigninFormLayout",
  data() {
    return {};
  },
  methods: {
    redirect: function (e) {
      this.$router
        .push({
          name: e,
        })
        .catch(() => {});
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
};
</script>

<style scoped>
.text-title {
  font-size: 30px;
  text-align: left;
  letter-spacing: 0px;
  color: #283c4d;
}

.text-enterprise-title {
  text-align: left;
  font-size: 22px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.text-subdescription {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  width: 95%;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.card-content:hover {
  cursor: pointer;
  opacity: 0.6;
}

.border-line-customer {
  height: 10px;
  background: transparent linear-gradient(269deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.border-line-vendor {
  height: 10px;
  background: transparent linear-gradient(90deg, #fffc81 0%, #7eff86 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.text-description {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.container-form-login {
  width: 100%;
  padding: 0px 15px;
}

.content-login {
  width: 100%;
}

@media (max-width: 720px) {
  .content-login {
    width: 100%;
  }
  .text-title {
    font-size: 25px;
  }

  .text-description {
    font-size: 14px;
  }

  .text-enterprise-title {
    font-size: 20px;
  }

  .text-subdescription {
    font-size: 12px;
  }

  .icon-arrow {
    width: 25px;
  }
}
</style>